import styled from "styled-components"
import { colors, space } from "../../../styles/vars"

export const StyleFooter = styled.footer`
  display: flex;
  padding: ${space.m};
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1310px;
`

export const StyleCopyright = styled.p`
  font-size: 12px;
  color: ${colors.purple.normal};
`

export const StyleSNSContainer = styled.div`
  display: flex;
  align-items: center;
`

export const StyleEmail = styled.a`
  color: ${colors.purple.normal};
`

export const StyleDivider = styled.span`
  width: 2px;
  margin: 0 16px;
  background-color: #707070;
`

export const StyleContactContainer = styled.div`
  display: flex;
`
