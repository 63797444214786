export const colors = {
  black: {
    normal: "#000000",
  },
  white: {
    normal: "#ffffff",
  },
  grey: {
    tab: "#adb7c5",
    lighter: "#6e7b8b",
    light: "#e7e7e7",
    normal: "#231f20",
    dark: "#161b21",
  },
  orange: {
    light: "#fd9d20",
    normal: "#ff7d08",
    dark: "#ba6108",
  },
  blue: {
    light: "#46adff",
    normal: "#00a0e7",
    dark: "#004489",
    darker: "#1a3b5b",
  },
  red: {
    light: "#ff5745",
    normal: "#f92b00",
    dark: "#b21100",
  },
  green: {
    light: "#32db86",
    normal: "#28a968",
    dark: "#1b8750",
    darker: "#00663f",
  },
  purple: {
    normal: "#620196",
  },
  meta: {
    light: "#ffffff",
    normal: "#d8d8d8",
    dark: "#bfbfbf",
  },
  social: {
    facebook: "#3b5998",
    twitter: "#1da1f2",
    youtube: "#cd201f",
    twitch: "#6441a5",
    line: "#00c300",
  },
  text: {
    normal: "#ededed",
    textLight: "#ededed",
    textStrong: "#ededed",
    textSelection: "#0d89eb",
  },
  primary: "#ff7d08",
  bodyBackground: "#2f353d",
  background: "#2f353d",
  linkVisited: "#ff7d08",
  linkHover: "#fd9d20",
  linkInvert: "#ff7d08",
}

export const space = {
  xxs: "2px",
  xs: "4px",
  s: "8px",
  m: "20px",
  l: "50px",
  xl: "100px",
}

// https://type-scale.com
// base size 20px
// scale 1.500 (perfect fifth)
export const fsize = {
  tiny: "12px",
  small: "14px",
  normal: "16px",
  big: "18px",
  bigger: "24px",
  biggest: "48px",
}

export const fweight = {
  w1: 100,
  w2: 200,
  w3: 300,
  w4: 400,
  w5: 500,
  w6: 600,
  w7: 700,
  w8: 800,
  w9: 900,
}

export const LINEH = 1.3

// font-size: ${fsize.t3};

export const zindex = {
  one: 1,
  ten: 10,
  alpha: 100,
  beta: 500,
  ganma: 700,
  overlay: 800,
  offmenu: 900,
  offcanvas: 1000,
  modal: 1100,
  preloader: 2000,
}

export const fonts = {
  types: {
    familySansSerif: "sans-serif",
    japaneseNoto: '"Noto Sans Japanese","Noto Sans",sans-serif',
    playbrain: `'Playbrain',"Noto Sans Japanese","Noto Sans",sans-serif`,
  },
  sizes: {
    1: "30px",
    2: "24px",
    3: "18px",
    4: "16px",
    5: "14px",
    6: "12px",
    7: "10px",
    8: "8px",
    large: "24px",
    medium: "18px",
  },
  weight: {
    light: "300",
    normal: "400",
    bold: "700",
    titleNormal: "400",
    titleBold: "400",
  },
}

export const sizes = {
  columnGap: "20px",
  nav: {
    height: "70px",
    heightDekki: "30px",
    heightSub: "50px",
  },
  radius: "3px",
  wrapper: "740px",
  maxWidth: "1120px",
  edgePadding: "10px",
  tablet: "769px",
  iphone4: "320px",
  iphone5: "374px",
  iphone6plus: "540px",
}
