import React from "react"
import instagramLogo from "../../../icons/instagram"
import ContentContainer from "../../atoms/ContentContainer"
import {
  StyleCopyright,
  StyleEmail,
  StyleFooter,
  StyleSNSContainer,
  StyleContactContainer,
  StyleDivider,
} from "./styles"

const Footer = () => (
  <StyleFooter>
    <StyleCopyright>© {new Date().getFullYear()} Latom</StyleCopyright>
    <StyleContactContainer>
      <StyleEmail href="mailto:hello@latom.net" target="_top">
        hello@latom.net
      </StyleEmail>
      <StyleDivider></StyleDivider>
      <StyleSNSContainer>
        <a
          href="https://www.instagram.com/latom_agency/"
          target="_blank"
          rel="noopener noreferrer"
        >
          {instagramLogo}
        </a>
      </StyleSNSContainer>
    </StyleContactContainer>
  </StyleFooter>
)

export default Footer
