import React from "react"

const instagramLogo = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="21"
    height="20.995"
    viewBox="0 0 21 20.995"
  >
    <path
      id="instagram-brands"
      d="M10.427,36.94a5.383,5.383,0,1,0,5.383,5.383A5.374,5.374,0,0,0,10.427,36.94Zm0,8.883a3.5,3.5,0,1,1,3.5-3.5,3.506,3.506,0,0,1-3.5,3.5Zm6.859-9.1a1.256,1.256,0,1,1-1.256-1.256A1.253,1.253,0,0,1,17.286,36.72Zm3.565,1.274a6.213,6.213,0,0,0-1.7-4.4,6.254,6.254,0,0,0-4.4-1.7c-1.733-.1-6.929-.1-8.662,0a6.245,6.245,0,0,0-4.4,1.691,6.234,6.234,0,0,0-1.7,4.4c-.1,1.733-.1,6.929,0,8.662a6.213,6.213,0,0,0,1.7,4.4,6.262,6.262,0,0,0,4.4,1.7c1.733.1,6.929.1,8.662,0a6.213,6.213,0,0,0,4.4-1.7,6.254,6.254,0,0,0,1.7-4.4C20.95,44.918,20.95,39.727,20.851,37.994ZM18.612,48.511a3.543,3.543,0,0,1-2,2c-1.382.548-4.661.422-6.189.422s-4.811.122-6.189-.422a3.543,3.543,0,0,1-2-2c-.548-1.382-.422-4.661-.422-6.189s-.122-4.811.422-6.189a3.543,3.543,0,0,1,2-2c1.382-.548,4.661-.422,6.189-.422s4.811-.122,6.189.422a3.543,3.543,0,0,1,2,2c.548,1.382.422,4.661.422,6.189S19.16,47.134,18.612,48.511Z"
      transform="translate(0.075 -31.825)"
      fill="black"
    />
  </svg>
)

export default instagramLogo
