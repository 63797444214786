import React from "react"
import styled from "styled-components"

const ContentContainer = props => (
  <StyleContainer>{props.children}</StyleContainer>
)

const StyleContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 1310px;
`

export default ContentContainer
